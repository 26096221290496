import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// import typer from 'typer-js';
import cn from 'clsx';

import Layout from 'components/Layout';
import Meta from 'components/Meta';
import { Link } from 'gatsby';

import FeatureContainer from '../components/FeatureContainer';
import TestimonialsSlider from '../components/TestimonialsSlider/TestimonialsSlider';
import SubHeader from '../components/SubHeader/SubHeader';
import NewFaq from '../components/NewFaq/NewFaq';
import Reviews from '../components/Reviews';
import JoinWaitlist from '../components/JoinWaitlist/JoinWaitlist';

import { faqDataCustomerFeedback } from '../utils/contentProvider/faqDataCommon';
import 'styles/usecases.scss';
import { btnOnClick } from '../utils/contentProvider/function';

const CustomerFeedback = ({ location }) => {
  const [activeSection, setActiveSection] = useState(null);
  const [showVideoModal, setVideoModal] = useState(false);
  const [showWaitlistModal, setShowWaitlistModal] = useState(false);

  // const typerBlock = useRef();

  const toggleWaitlistModal = () => {
    setShowWaitlistModal(!showWaitlistModal);
  };

  // const runTyper = useCallback(() => {
  //   const kill = new Event('killTyper');
  //   document.body.dispatchEvent(kill);

  //   const words = ['feedback', 'support'];

  //   const instance = typer(typerBlock.current).cursor().line();

  //   words.forEach((word) => {
  //     instance

  //       .empty()
  //       .continue(word, 100)
  //       .pause(1000) // Pause for 1 second after each word is typed.
  //       .back('all', 5); // Erase that word, leaving a blank slate for the next one.
  //   });

  //   return instance.repeat(Infinity, true);
  // }, []);

  // useEffect(() => {
  //   runTyper();
  // }, [runTyper]);

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll('section');
      const currentPosition = window.scrollY + window.innerHeight / 2;

      sections.forEach((section) => {
        if (
          section.offsetTop <= currentPosition &&
          section.offsetTop + section.offsetHeight > currentPosition
        ) {
          setActiveSection(section.getAttribute('id'));
        }
      });
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  function toggleVideoModal() {
    setVideoModal(!showVideoModal);
  }
  return (
    <Layout location={location} hideTopBar productPage hideHeader>
      {() => (
        <>
          <Meta
            title="Customer Feedback Tool by Ruttl"
            description="Collect customer feedback with live chat, visual bug reporting, and more. Build better products with the help of Ruttl."
            url="https://ruttl.com/customer-feedback-tool/"
          />

          <div
            id="demo-modal"
            className={cn(['demo-modal', { show: showVideoModal }])}>
            <button
              type="button"
              className="button-unstyled"
              onClick={toggleVideoModal}>
              <img src="/assets/img/close.svg" alt="close" title="close" />
            </button>
            {showVideoModal && (
              <iframe
                width="560"
                height="315"
                src="https://www.youtube-nocookie.com/embed/Tz7WzxUc7tk"
                frameBorder="0"
                title="ruttl demo"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            )}
          </div>

          {showWaitlistModal && (
            <JoinWaitlist
              toggleWaitlistModal={toggleWaitlistModal}
              showWaitlistModal
            />
          )}

          <SubHeader
            productType="customer-feedback-tool"
            productName="Customer Feedback"
            showVideoModal
            setActiveSection={setActiveSection}
            activeSection={activeSection}
            toggleWaitlistModal={toggleWaitlistModal}
            showWaitlistModal={showWaitlistModal}
            setShowWaitlistModal={setShowWaitlistModal}
          />
          <main className="usecases-styled-main">
            <section className="usecase-hero-vertical">
              <div className="container">
                <div className="center">
                  {/* <img src="/assets/img/bot.svg" alt="bot icon" /> */}
                  <h1 className="mx-auto" style={{ maxWidth: 730 }}>
                    Chat with customers to gather real-time feedback
                  </h1>
                  <p className="mx-auto" style={{ maxWidth: 650 }}>
                    With live chat, visual bug reporting and collection of
                    testimonials, we help you improve your product, boost sales
                    and strengthen customer relationships.
                  </p>

                  <div className="flex gap-8 justify-content-center flex-wrap">
                    <button
                      type="button"
                      className="button"
                      id="get-started-free-cft-hero"
                      onClick={btnOnClick({
                        to: 'https://chat.ruttl.com/',
                      })}>
                      Get started for free
                    </button>
                    <a
                      href="https://calendly.com/setup-demo-with-ruttl/30min"
                      target="_blank"
                      rel="noopener noreferrer"
                      type="button"
                      id="book-demo-cft-hero"
                      className="button book-demo">
                      Book a Demo
                    </a>
                  </div>
                </div>

                <div className="usecase-vertical-asset center">
                  <video
                    src="https://static.ruttl.com/customer-feedback-hero.mp4"
                    autoPlay
                    loop
                    muted
                    playsInline></video>
                </div>
              </div>
            </section>
            {/* <section
              className="styled-bug-tracking"
              style={{ backgroundColor: '#fff' }}
              id="how-it-works">
              <div className="container">
                <h2 className="center" id="view-demo">
                  Create your own AI Chatbot
                </h2>

                <div className="flex-2 flex flex-wrap align-items-stretch">
                  <div className="wrapper">
                    <div className="card-single">
                      <div className="card-block">
                        <div className="card-icon">
                          <img
                            loading="lazy"
                            src="/assets/img/link.svg"
                            alt="url icon"
                            title="url icon"
                          />
                        </div>
                        <div className="card-desc">
                          <h3>Enter your website URL</h3>
                          <p>
                            Add your website URL and ruttl will fetch all pages
                            for you, so you can begin automating user engagement
                            through our AI powered live chat.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="wrapper">
                    <div className="card-single">
                      <div className="card-block">
                        <div className="card-icon">
                          <img
                            loading="lazy"
                            src="/assets/img/cube.svg"
                            alt="cube icon"
                            title="cube icon"
                          />
                        </div>
                        <div className="card-desc">
                          <h3>Start training</h3>
                          <p>
                            Train your AI bot on your website data by adding a
                            CSV file or manually entering a set of pre-defined
                            queries.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="wrapper">
                    <div className="card-single">
                      <div className="card-block">
                        <div className="card-icon">
                          <img
                            loading="lazy"
                            src="/assets/img/add-plugin.svg"
                            alt="install snippet icon"
                            title="install snippet icon"
                          />
                        </div>
                        <div className="card-desc">
                          <h3>Install Widget</h3>
                          <p>
                            Add the code snippet to your website and, our
                            customizable chat widget powered by your newly
                            created AI chatbot will be added to the website.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="center">
                  <button
                    className="button"
                    type="button"
                    onClick={toggleWaitlistModal}>
                    Join Waitlist
                  </button>
                </div>
              </div>
            </section> */}

            <section
              className="styled-bug-tracking"
              style={{ backgroundColor: '#fff' }}>
              <div className="container">
                <h2 className="center" id="view-demo">
                  Drive higher activation, growth, and retention
                </h2>

                <div className="flex-2 flex flex-wrap align-items-stretch">
                  <div className="wrapper">
                    <div className="card-single">
                      <div className="card-block">
                        <div className="card-icon">
                          <img
                            loading="lazy"
                            src="/assets/img/circle-with-tick.svg"
                            alt="circle with tick icon"
                            title="circle with tick icon"
                          />
                        </div>
                        <div className="card-desc">
                          <h3>Acquire</h3>
                          <p>
                            Live chat with website visitors and convert them
                            into buyers by understanding what they are looking
                            for or have any doubts.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="wrapper">
                    <div className="card-single">
                      <div className="card-block">
                        <div className="card-icon">
                          <img
                            loading="lazy"
                            src="/assets/img/cube.svg"
                            alt="cube icon"
                            title="cube icon"
                          />
                        </div>
                        <div className="card-desc">
                          <h3>Engage</h3>
                          <p>
                            Understand the issues faced by customers and solve
                            them in real time. Keep them up to date with the
                            progress.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="wrapper">
                    <div className="card-single">
                      <div className="card-block">
                        <div className="card-icon">
                          <img
                            loading="lazy"
                            src="/assets/img/circle-with-right-arrow.svg"
                            alt="circle with right arrow icon"
                            title="circle with right arrow icon"
                          />
                        </div>
                        <div className="card-desc">
                          <h3>Retain</h3>
                          <p>
                            Know the feature your customer wants with
                            ruttl&apos;s feature request. Share with product
                            team and track the progress.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="center demo">
                  <button
                    type="button"
                    className="button"
                    id="get-started-free-cft-fold2"
                    onClick={btnOnClick({
                      to: 'https://chat.ruttl.com/signup',
                    })}>
                    Get started for free
                  </button>
                </div>
              </div>
            </section>

            <section className="section-pad">
              <div className="container " style={{ marginTop: 0 }}>
                <div className="flex-row">
                  <div className="col col-8 offset-2">
                    <div className="feedback bug-track-feedback">
                      <div className="feebackImage">
                        <img
                          loading="lazy"
                          src="/assets/img/perry.jpg"
                          alt="For-agencies"
                          title="For-agencies"
                        />
                      </div>
                      <div style={{ maxWidth: 560 }}>
                        <h3>
                          “With ruttl, we’ve brought our response time down to a
                          few minutes. Our support agents are able to
                          communicate effectively & rectify users’ issues
                          quickly. 10/10 recommended!”
                        </h3>
                        <p>
                          <span>-Perry Steward, Founder,&nbsp;</span>
                          MadX Digital
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="usecases-styled-main" id="features">
              <div className="section features-main bugtrack-features">
                <div className="container">
                  <div className="section-head">
                    <h2 className="center mx-auto" style={{ maxWidth: 700 }}>
                      Make your business stronger with ruttl&apos;s customer
                      feedback
                    </h2>
                  </div>

                  <FeatureContainer>
                    <div className="feature-set-single">
                      <div className="flex flex-wrap align-items-center ">
                        <div className="feature-img">
                          <div className="feature-img-bg">
                            <img
                              loading="lazy"
                              src="/assets/img/build-relationship.png"
                              alt="Live Chat with customers"
                              title="Live Chat with customers"
                            />
                          </div>
                        </div>
                        <div className="feature-txt">
                          <span className="feature-tag">Live Chat</span>
                          <h3 className="feature-head">
                            Build customer relationships
                          </h3>
                          <p>
                            You can chat live now or reach customers via email
                            if they’re no longer online. Message with confidence
                            based on delivery status.
                          </p>
                          {/* <button
                            type="button"
                            onClick={() => toggleWaitlistModal()}
                            className="view-product">
                            Join Waitlist
                          </button> */}
                        </div>
                      </div>
                    </div>
                  </FeatureContainer>
                  <FeatureContainer>
                    <div className="feature-set-single">
                      <div className="flex flex-wrap align-items-center ">
                        <div className="feature-img">
                          <div className="feature-img-bg">
                            <img
                              loading="lazy"
                              src="/assets/img/customer-issues.png"
                              alt="Customer Issues"
                              title="Customer Issues"
                            />
                          </div>
                        </div>
                        <div className="feature-txt">
                          <span className="feature-tag">Report Bugs</span>
                          <h3 className="feature-head">
                            Understand if customers are facing any issues
                          </h3>
                          <p>
                            Each member of your team will get notified about
                            these bugs and you can then assign tasks, set
                            deadlines and prioritize them as per the need. Jira
                            with fewer steps.
                          </p>
                          {/* <button
                            type="button"
                            onClick={() => toggleWaitlistModal()}
                            className="view-product">
                            Join Waitlist
                          </button> */}
                        </div>
                      </div>
                    </div>
                  </FeatureContainer>
                  <FeatureContainer>
                    <div className="feature-set-single">
                      <div className="flex flex-wrap align-items-center ">
                        <div className="feature-img">
                          <div className="feature-img-bg">
                            <img
                              loading="lazy"
                              src="/assets/img/feature-request.png"
                              alt="Feature Requests"
                              title="Feature Requests"
                            />
                          </div>
                        </div>
                        <div className="feature-txt">
                          <span className="feature-tag">Feature Request</span>
                          <h3 className="feature-head">
                            Know what your customers need
                          </h3>
                          <p>
                            Build better products by listening to the voice of
                            your customers. All requests can be sent to your
                            product manager who can then check the occurrence of
                            a feature request and discuss its impact internally.
                          </p>
                          {/* <button
                            type="button"
                            onClick={() => toggleWaitlistModal()}
                            className="view-product">
                            Join Waitlist
                          </button> */}
                        </div>
                      </div>
                    </div>
                  </FeatureContainer>
                  <FeatureContainer>
                    <div className="feature-set-single">
                      <div className="flex flex-wrap align-items-center ">
                        <div className="feature-img">
                          <div className="feature-img-bg">
                            <img
                              loading="lazy"
                              src="/assets/img/customer-testimonial.png"
                              alt="get customer testimonials"
                              title="get customer testimonials"
                            />
                          </div>
                        </div>
                        <div className="feature-txt">
                          <span className="feature-tag">
                            Collect Testimonials
                          </span>
                          <h3 className="feature-head">
                            Collect and display customer testimonials with ease
                          </h3>
                          <p>
                            Collect text and video testimonials from your
                            customers with no need for a developer or website
                            hosting.
                          </p>
                          {/* <button
                            type="button"
                            onClick={() => toggleWaitlistModal()}
                            className="view-product">
                            Join Waitlist
                          </button> */}
                        </div>
                      </div>
                    </div>
                  </FeatureContainer>
                </div>
              </div>

              <section className="section-pad">
                <div className="container">
                  <div className="section-head">
                    <h2 className="">Make ruttl your own</h2>
                  </div>

                  <div className="cf-video">
                    <video
                      src="https://static.ruttl.com/make-ruttl-your-own.mp4"
                      autoPlay
                      loop
                      muted
                      playsInline></video>
                  </div>
                </div>
              </section>
              <TestimonialsSlider />
              <section className="section-gap">
                <div className="container">
                  <h2 className="center" style={{ marginBottom: 50 }}>
                    Explore other products
                  </h2>
                  <div className="flex-row justify-content-center">
                    {[
                      {
                        id: 0,
                        icon: '/assets/img/website-feedback-icon.svg',
                        heading: 'Website Feedback',
                        text: 'Comment on live websites, report bugs, and make real-time CSS edits for precise feedback',
                        url: '/website-feedback-tool/',
                      },

                      {
                        id: 1,
                        icon: '/assets/img/mobile-app-feedback-icon.svg',
                        heading: 'Mobile App Feedback',
                        text: 'Mark issues directly on mobile application, organise feedback using our internal workflow',
                        url: '/mobile-app-feedback/',
                      },
                    ].map(({ id, icon, heading, text, url }) => (
                      <div key={id} className="col col-3 col-md-6">
                        <Link to={url} className="icon-text-box">
                          <div className="box-icon">
                            <img src={icon} alt="" />
                          </div>
                          <div className="box-desc">
                            <h3>{heading}</h3>
                            <p>{text}</p>
                          </div>
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>
              </section>
              <Reviews />
              <NewFaq data={faqDataCustomerFeedback} />
            </section>
          </main>
        </>
      )}
    </Layout>
  );
};
CustomerFeedback.propTypes = {
  location: PropTypes.object,
};

export default CustomerFeedback;
